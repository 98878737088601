import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import StatusBar from './components/StatusBar';
import AllAthletesRadioGroup from './components/AllAthletesRadioGroup';
import AthleteProfile from './components/AthleteProfile';
import Grid from '@mui/material/Grid';
import { Box, Typography, List, ListItem, Paper } from '@mui/material';
import { useMediaQuery,useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';


const ActiveSelection = ({ eventInfo }) => {
  return (
    < React.Fragment>
      <Box mb={2}>
        <Typography variant="h6" component="div">
          <strong>Competitions for Period Ending:</strong> {eventInfo.end}
        </Typography>
      </Box>
      <Box mb={2}>
        <List>
          {eventInfo.competitions.map((competition, index) => (
            <ListItem  disablePadding key={index}> 
               <Typography variant="body2">{competition}</Typography>
            </ListItem >
          ))}
        </List>
      </Box>
    </React.Fragment>
  );
};
export default function Triathletes({userid}) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm')); // Adjust breakpoint as needed
  const [athletes, setAthletes] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState({ message: '', type: '' });
  const [eventInfo, setEventInfo] = useState({ competitions: [], end: '',locked:false });
  const [numSelected, setNumSelected] = useState(0);
  const [allAthletes, setAllAthletes] = useState(false);
  const [textFilter, setTextFilter] = useState("");
  const [changesLockedText, SetChangesLockedText] = useState("");
  const [openAthleteProfile, setOpenAthleteProfile] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState(0);
  var counter = 0;
  
  useEffect(() => {
    fetch('/api/current_events')
      .then((res) => res.json())
      .then((data) => {
        setEventInfo({competitions:data.competitions, end: data.end, locked:data.locked});
        if (data.locked)
          SetChangesLockedText("(period is locked)")
        else
          SetChangesLockedText("")
      });
  }, []);  

  
  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 20, // Default footer height
          padding: '0 16px',
          backgroundColor: '#f5f5f5',
          borderTop: '1px solid #ddd',
        }}
      >
        <Typography variant="body2" color="textSecondary">
        {numSelected} athletes selected {changesLockedText}
        </Typography>
      </Box>
    );
  };

  const showStatusBar = (message, type) => {
    setStatus({ message, type });
    setTimeout(() => {
      setStatus({ message: ``, type: '' });
    }, 3000); // Hide the status bar after 3 seconds
  };
  const toggleSelection = async (e, cellValues) => {
    e.preventDefault();
    setProcessing(true);
    console.log("Toggle", cellValues.value, cellValues.row.id )
    try {
      let res = await axios.post('/api/pick/triathlete', {id:cellValues.row.id, select: !cellValues.value, user: userid });
      
      setAthletes((prevRows) => {
        const updatedRows = prevRows.map((row) =>
          row.id === cellValues.row.id ? { ...row, selected: !cellValues.value } : row
        );
        setNumSelected(updatedRows.filter((row) => row.selected).length);
        return updatedRows;
      })
      showStatusBar(res.data, "success");
    } catch (error) {
      console.error('There was an error!', error);
      showStatusBar(error.response.data, "error")
    }
    setProcessing(false);
  };
  
  const ClickableCell = styled('div')({
    cursor: 'pointer',
    color: '#1976d2', // Example color for clickable cells
    '&:hover': {
      textDecoration: 'underline',
    },
  });


  const handleCellClick = (params) => {
    setSelectedAthlete(params.id)
    setOpenAthleteProfile(true)
  };

  const columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 120 ,
    renderCell: (params) => (
      <ClickableCell onClick={() => handleCellClick(params)}>
        {params.value}
      </ClickableCell>
    ),

     },
    { field: 'country', headerName: 'Country', flex: 0.5, minWidth: 100 },
    { field: 'gender', headerName: 'Gender', flex: 0.5, minWidth: 100 },
    { field: 'results', headerName: 'Results', flex: 0.5, minWidth: 100 },
    { field: 'score', headerName: 'Score', flex: 0.5, minWidth: 90 },
    { field: 'score_rank', headerName: 'Rank', flex: 0.5, minWidth: 90 },
    { field: 'on_start_list', headerName: 'On Start List', flex: 1.5, minWidth: 120 },
    { field: "selected", headerName: '', flex: 1 , 
      renderCell: (cellValues) => {
      if (cellValues.value){
          return (
            <Button  variant="contained" disabled={processing || eventInfo.locked} color="primary" size="small" onClick={(event) => { toggleSelection(event, cellValues);}}  >
              UnSelect
            </Button>
          );
        }else{
          return (
            <Button  variant="outlined"  disabled={processing || eventInfo.locked} color="primary" size="small" onClick={(event) => { toggleSelection(event, cellValues);}}>
              Select
            </Button>
          )
        };
      }
    },
  ]

  useEffect(() => {
    fetch(`/api/triathletes/${userid}/All/${allAthletes}/${textFilter ? textFilter:"All"}`)
      .then((res) => res.json())
      .then((data) => {
        setAthletes(data);
        const selectedCount = data.filter((athlete) => athlete.selected === true).length;
        setNumSelected(selectedCount);
      });
  }, [userid, allAthletes,textFilter]);  

  const onTextChange =  async (event) => {
    counter ++;
    let localcounter = counter;
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (localcounter === counter ) {
      setTextFilter(event.target.value);
    }
  }
  return (
    <React.Fragment>
      <AthleteProfile open={openAthleteProfile} setOpen={setOpenAthleteProfile} athleteId = {selectedAthlete}/>
        <Grid container spacing={2} >
          <Grid item xs={12} md={8} lg={9}>
            <Paper elevation={3} 
                sx={{ padding: 2, marginBottom: 2,display: 'flex', 
                    flexDirection: 'column',height: 200, }}>
              <ActiveSelection  eventInfo={eventInfo}/>
            </Paper>
          </Grid>
          <Grid item  xs={12} md={4} lg={3}>
            <Paper elevation={3} 
              sx={{ padding: 2, marginBottom: 2,display: 'flex', 
                  flexDirection: 'column',height: 200, }}>
              <AllAthletesRadioGroup allAthletes = {allAthletes} setAllAthletes = {setAllAthletes} />
              <TextField  label="Athlete Filter" 
                  onChange={onTextChange}
                  variant="outlined" />
            </Paper>
          </Grid>
        </Grid>
        <StatusBar message={status.message} type={status.type} />
        <DataGrid
              disableColumnMenu
              autoHeight
              rows={athletes}
              columns={columns}
              // hideFooter
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                columns: {
                    columnVisibilityModel: {
                      id: false,
                      country:!isMobile,
                      gender:!isMobile,
                      score_rank:!isMobile,
                      on_start_list:!isMobile,
                    },
                },
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
    
              pageSizeOptions={[10,15, 25,50]}
              density={'compact'}
              disableRowSelectionOnClick
              disableColumnSelector
              ignoreDiacritics
            />
    </React.Fragment>
  );
}
