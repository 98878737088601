import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Summary from './Summary';
import Ranking from './Ranking';
import Triathletes from './Triathletes';
import Rules from './Rules'
import getLPTheme from './getLPTheme';
import ResponsiveAppBar from './components/MenuBar'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://kupala-tri.com/">
        Kupala Tri
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Dashboard() {
  const [userid, setUserid] = React.useState(0);
  const [activeComponent, setActiveComponent] = React.useState('Summary');

  const defaultTheme = createTheme(getLPTheme('light'));

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar position="absolute" >
          <ResponsiveAppBar setActiveComponent = {setActiveComponent} userid = {userid} setUserid = {setUserid} />
        </MuiAppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {activeComponent === 'Summary' && <Summary  userid={userid} />}
            {activeComponent === 'Weekly Picks' && <Triathletes userid={userid}/>}            
            {activeComponent === 'Ranking' && <Ranking userid={userid}/>}            
            {activeComponent === 'Rules' && <Rules/>}            
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
